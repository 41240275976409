import { GetServerSideProps } from 'next';
import React from 'react';
import HomeComponent from '../components/Home/HomeComponent';
import Layout from '../components/Layout/Layout';
import { apiRequest } from '../components/util/requests/requests';
import { PageMetadata } from '../components/util/SeoMetaInformationComponent/SeoMetaInformationComponent';
import { Resource } from './resources';
import { Work } from './resources/our-work';

export interface HomeProps {
    works: Array<Work>;
    resources: Array<Resource>;
    pageMetadata?: PageMetadata;
}

export const getServerSideProps: GetServerSideProps = async ({ params }) => {
    const works = await apiRequest('/works?_limit=3', true);
    const worksInHomepage = await apiRequest('/works?_limit=3&show_on_homepage=true', true);
    const resourcesForWorks = await apiRequest('/resources?_limit=3&show_on_homepage=true');

    const displayedSolutions =
        [...resourcesForWorks, ...worksInHomepage, ...works].filter(
            (displaySolutions, index) => index < 3
        ) ?? [];
    const excludedDisplaySolutionParams =
        displayedSolutions.length > 0
            ? displayedSolutions
                  .map((displayedSolution) => `&id_nin=${displayedSolution.id}`)
                  .join('')
            : '';

    const resources = await apiRequest(`/resources?_limit=3${excludedDisplaySolutionParams}`, true);
    const pageMetadata = await apiRequest('/page-metas?key=HOME_PAGE');

    return {
        props: {
            works: displayedSolutions,
            resources: resources ?? [],
            pageMetadata: pageMetadata && pageMetadata.length > 0 ? pageMetadata[0] : null,
        },
    };
};

const Home = ({ works, resources, pageMetadata }: HomeProps) => {
    return (
        <Layout seoMetadata={pageMetadata?.seo}>
            <HomeComponent works={works} resources={resources} />
        </Layout>
    );
};

export default Home;
