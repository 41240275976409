import React, { CSSProperties, ReactNode } from 'react';
import styles from '../layout.module.scss';

type ProfilePicturesProps = {
    extraClassNames?: string;
    extraStyles?: CSSProperties;
    children: ReactNode;
};

const DefaultWidthContainer = ({
    extraClassNames = '',
    extraStyles,
    children,
}: ProfilePicturesProps) => (
    <div className={`${styles.container1400} ${extraClassNames}`} style={extraStyles}>
        {children}
    </div>
);

export default DefaultWidthContainer;
