import Link from 'next/link';
import React, { FormEvent, useState } from 'react';
import DefaultWidthContainer from '../../Layout/Containers/DefaultWidthContainer';
import styles from './newsletter.module.scss';

const NewsletterComponent = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [tag, setTag] = useState('');
    const [emailBorderColor, setEmailBorderColor] = useState('var(--primary-black');
    const [firstNameColor, setFirstNameColor] = useState('var(--primary-black');
    const [lastNameColor, setLastNameColor] = useState('var(--primary-black');
    const [visibleEmailLabel, toggleVisibleEmailLabel] = useState(0);
    const [visibleFirstNameLabel, toggleVisibleFirstNameLabel] = useState(0);
    const [visibleLastNameLabel, toggleVisibleLastNameLabel] = useState(0);
    const [invalidEmail, toggleInvalidEmail] = useState('none');
    const [invalidFirstName, toggleInvalidFirstName] = useState('none');
    const [invalidLastName, toggleInvalidLastName] = useState('none');
    const [signedUp, setSignedUp] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        setSubmitError(false);
        if (email.length > 0) {
            toggleInvalidEmail('none');
        } else {
            setEmailBorderColor('var(--primary-red');
            toggleInvalidEmail('block');
        }
        if (firstName.length > 0) {
            toggleInvalidFirstName('none');
        } else {
            setFirstNameColor('var(--primary-red');
            toggleInvalidFirstName('block');
        }
        if (lastName.length > 0) {
            toggleInvalidLastName('none');
        } else {
            setLastNameColor('var(--primary-red');
            toggleInvalidLastName('block');
        }
        if (email.length > 0 && firstName.length > 0 && lastName.length > 0 && tag.length === 0) {
            const formData = new FormData();
            formData.append('mauticform[first_name1]', firstName);
            formData.append('mauticform[last_name1]', lastName);
            formData.append('mauticform[email]', email);
            formData.append('mauticform[formId]', '2');
            formData.append('mauticform[return]', '');
            formData.append('mauticform[submit]', '');
            formData.append('mauticform[formName]', 'subscibretonewsletter');
            fetch(`${process.env.NEXT_PUBLIC_MAUTIC_URL}/form/submit?formId=2`, {
                method: 'POST',
                body: formData,
            })
                .then((response) => {
                    if (response.status == 200) {
                        setSignedUp(true);
                    } else {
                        setSubmitError(true);
                    }
                })
                .catch((error) => {
                    setSubmitError(true);
                });
        }
    };

    return (
        <DefaultWidthContainer>
            <h2 className={`${styles.h2} ${styles.signUpHeader}`}>Sign up to our newsletter</h2>
            <p className={styles.info}>
                Sign up to our newsletter to receive the latest updates and news from Noria. <br />
                You can find our &nbsp;
                <Link href="/privacy">
                    <a>privacy policy here</a>
                </Link>
            </p>
            {signedUp ? (
                <div style={{ paddingBottom: '100px' }}>
                    Thank you for signing up to our newsletter!
                </div>
            ) : (
                <form className={styles.form} onSubmit={onSubmit}>
                    <div className={styles.labelContainer}>
                        <label
                            htmlFor="email"
                            className={styles.label}
                            style={{ opacity: visibleEmailLabel }}
                        >
                            Your email*
                        </label>
                        <input
                            id="email"
                            type="email"
                            className={styles.input}
                            style={{ borderBottomColor: emailBorderColor }}
                            placeholder="Your email*"
                            value={email}
                            name="mauticform[email]"
                            onChange={(event) => {
                                setEmail(event.target.value);
                                if (event.target.value.length > 0) {
                                    setEmailBorderColor('var(--primary-black');
                                    toggleInvalidEmail('none');
                                    toggleVisibleEmailLabel(1);
                                } else {
                                    toggleVisibleEmailLabel(0);
                                }
                            }}
                        />
                        <span className={styles.invalid} style={{ display: invalidEmail }}>
                            Please fill out this field
                        </span>
                    </div>
                    <div className={styles.labelContainer}>
                        <label
                            htmlFor="firstName"
                            className={styles.label}
                            style={{ opacity: visibleFirstNameLabel }}
                        >
                            Your first name*
                        </label>
                        <input
                            id="firstName"
                            type="text"
                            className={styles.input}
                            style={{ borderBottomColor: firstNameColor }}
                            placeholder="First name*"
                            value={firstName}
                            name="mauticform[first_name1]"
                            onChange={(event) => {
                                setFirstName(event.target.value);
                                if (event.target.value.length > 0) {
                                    setFirstNameColor('var(--primary-black');
                                    toggleInvalidFirstName('none');
                                    toggleVisibleFirstNameLabel(1);
                                } else {
                                    toggleVisibleFirstNameLabel(0);
                                }
                            }}
                        />
                        <span className={styles.invalid} style={{ display: invalidFirstName }}>
                            Please fill out this field
                        </span>
                    </div>
                    <div className={styles.labelContainer}>
                        <label
                            htmlFor="lastName"
                            className={styles.label}
                            style={{ opacity: visibleLastNameLabel }}
                        >
                            Your last name*
                        </label>
                        <input
                            id="lastName"
                            type="text"
                            className={styles.input}
                            style={{ borderBottomColor: lastNameColor }}
                            placeholder="Last name*"
                            value={lastName}
                            name="mauticform[last_name1]"
                            onChange={(event) => {
                                setLastName(event.target.value);
                                if (event.target.value.length > 0) {
                                    setLastNameColor('var(--primary-black');
                                    toggleInvalidLastName('none');
                                    toggleVisibleLastNameLabel(1);
                                } else {
                                    toggleVisibleLastNameLabel(0);
                                }
                            }}
                        />
                        <span className={styles.invalid} style={{ display: invalidLastName }}>
                            Please fill out this field
                        </span>
                    </div>
                    <input
                        style={{ display: 'none' }}
                        name="tag"
                        type="text"
                        onChange={(event) => {
                            setTag(event.target.value);
                        }}
                        value={tag}
                    />
                    <div>
                        <button
                            type="submit"
                            className="button-link button-large"
                            style={{ minWidth: '85px' }}
                        >
                            Sign up
                        </button>
                        {submitError ? (
                            <div
                                style={{
                                    fontSize: '14px',
                                    color: 'var(--primary-red)',
                                    paddingTop: '10px',
                                }}
                            >
                                Something went wrong. Please try again!
                            </div>
                        ) : null}
                    </div>
                </form>
            )}
        </DefaultWidthContainer>
    );
};

export default NewsletterComponent;
