import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { isBrowser, isMobileOnly, isTablet } from 'react-device-detect';
import { HomeProps } from '../../pages';
import DefaultWidthContainer from '../Layout/Containers/DefaultWidthContainer';
import FullWidthContainer from '../Layout/Containers/FullWidthContainer';
import LargeWidthContainer from '../Layout/Containers/LargeWidthContainer';
import TripleColumnContainer from '../Layout/Containers/TripleColumnContainer';
import PageHeaderComponent from '../util/PageHeader/PageHeaderComponent';
import styles from './home.module.scss';
import NewsletterComponent from './Newsletter/NewsletterComponent';

const DynamicWorkComponent = dynamic(() => import('../OurWork/Work/WorkComponent'));
const DynamicResourceComponent = dynamic(() => import('../Resources/Resource/ResourceComponent'));

const HomeComponent = ({ works, resources }: HomeProps) => {
    const [hasWorks, setWorks] = useState(false);
    const [hasResources, setResources] = useState(false);

    useEffect(() => {
        setWorks(works.length > 0 === true);
        setResources(resources.length > 0 === true);
    });

    const renderWorks = () => {
        if (works.length < 0) {
            return null;
        } else {
            return works.map((work) => (
                <DynamicWorkComponent
                    key={`noria-work-${work.seoUrl}`}
                    mainImage={work.mainImage}
                    heading={work.heading}
                    ingress={work.ingress}
                    resource_category={work.resource_category}
                    seoUrl={work.seoUrl}
                />
            ));
        }
    };

    const renderResources = () => {
        if (resources.length < 0) {
            return null;
        } else {
            return resources.map((resource) => (
                <DynamicResourceComponent
                    key={`noria-resource-${resource.seoUrl}`}
                    mainImage={resource.mainImage}
                    heading={resource.heading}
                    ingress={resource.ingress}
                    seoUrl={resource.seoUrl}
                    resource_category={resource.resource_category}
                    author_name={resource.author_name}
                    published_at={resource.published_at}
                    isShowAuthor={false}
                />
            ));
        }
    };

    return (
        <>
            <section className={styles.homepage}>
                <h1 className="screen-reader-only">Noria.no frontpage</h1>
                <div className={styles.backgroundVideoContainer}>
                    {(isTablet || isBrowser) && (
                        <video
                            className={`${styles.backgroundVideo}`}
                            autoPlay
                            loop
                            muted
                            playsInline
                        >
                            <source src="/videos/bg-gradient-desktop.mp4" type="video/mp4" />
                        </video>
                    )}

                    {isMobileOnly && (
                        <video
                            className={`${styles.backgroundVideo}`}
                            autoPlay
                            loop
                            muted
                            playsInline
                        >
                            <source src="/videos/bg-gradient-mobile.mp4" type="video/mp4" />
                        </video>
                    )}
                </div>
                <FullWidthContainer>
                    <PageHeaderComponent
                        quote="We are a technology partner delivering high-impact digital transformation through our banking and insurance solutions, digital project team and highly experienced consultants"
                        buttonText="Browse our solutions and services"
                    >
                        <h1>We specialise in digital and IT for financial services</h1>
                    </PageHeaderComponent>

                    {hasWorks ? (
                        <>
                            <TripleColumnContainer>{renderWorks()}</TripleColumnContainer>
                            <DefaultWidthContainer extraClassNames={styles.workLink}>
                                <Link href="/resources?type=OUR_WORK&type=CUSTOMER_STORIES">
                                    <a className={styles.link}>View all work items</a>
                                </Link>
                            </DefaultWidthContainer>
                        </>
                    ) : null}
                    {hasResources ? (
                        <>
                            <LargeWidthContainer>
                                <h2 className={styles.subHeader}>Resources</h2>
                            </LargeWidthContainer>
                            <TripleColumnContainer>{renderResources()}</TripleColumnContainer>
                            <DefaultWidthContainer extraClassNames={styles.resourcesLink}>
                                <Link href="/resources">
                                    <a className={styles.link}>View all resources</a>
                                </Link>
                            </DefaultWidthContainer>
                        </>
                    ) : null}
                </FullWidthContainer>
            </section>
            <section>
                <FullWidthContainer>
                    <NewsletterComponent />
                </FullWidthContainer>
            </section>
        </>
    );
};

export default HomeComponent;
