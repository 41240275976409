import React, { CSSProperties, ReactNode } from 'react';
import styles from '../layout.module.scss';
import DefaultWidthContainer from './DefaultWidthContainer';
import LargeWidthContainer from './LargeWidthContainer';

type TripleColumnProps = {
    extraClassNames?: string;
    extraStyles?: CSSProperties;
    children: ReactNode;
    largeContainer?: boolean;
};

const TripleColumnContainer = ({
    extraClassNames,
    extraStyles,
    children,
    largeContainer = true,
}: TripleColumnProps) => {
    if (largeContainer) {
        return (
            <LargeWidthContainer extraClassNames={extraClassNames} extraStyles={extraStyles}>
                <div className={styles.flexWrapTripleColumnFullWidth}>{children}</div>
            </LargeWidthContainer>
        );
    } else {
        return (
            <DefaultWidthContainer extraClassNames={extraClassNames} extraStyles={extraStyles}>
                <div className={styles.flexWrapTripleColumnFullWidth}>{children}</div>
            </DefaultWidthContainer>
        );
    }
};

export default TripleColumnContainer;
